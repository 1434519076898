<template>
  <section>
    <b-card no-body>
      <b-card-body>
        <b-tabs
          pills
          class="ll-tabs"
        >
          <!-- Basic Info -->
          <b-tab active>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Basic Info') }}</span>
            </template>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Client"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Client") }}</label>
                    <v-select
                      :options="customerList"
                      label="name"
                      @input="getCustomer1"
                      placeholder=""
                      v-model="detailInfo.orgId"
                    ></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Name") }}</label>
                    <b-form-input v-model="detailInfo.itemName"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Description") }}</label>
                    <b-form-input v-model="detailInfo.itemDesc"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Declare Name"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Declare Name") }}</label>
                    <b-form-input />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Code"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Code") }}</label>
                    <b-form-input v-model="detailInfo.itemCode"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Barcode"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Barcode") }}</label>
                    <b-form-input v-model="detailInfo.barcode"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Country of Origin"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Country of Origin") }}</label>
                    <b-form-input v-model="detailInfo.coo"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="UOM"
                    rules="required"
                  >
                    <label class="ll-boldText">UOM</label>
                    <v-select />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Declare Price')">
                  <b-form-input />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Price Before Discount')">
                  <b-form-input />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="HTS Code"
                    rules="required"
                  >
                    <label class="ll-boldText mr-2">HTS {{ $t("Code") }}</label>
                    <b-button
                      variant="primary"
                      @click="repeateAgain"
                    >
                      <span>{{ $t('Add') }}</span>
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                      />
                    </b-button>
                    <b-row v-for="(item,index) in items" :key="index" :id="index"
                      class="mt-1">
                      <b-col>
                        <v-select />
                      </b-col>
                      <b-col>
                        <b-form-input />
                      </b-col>
                      <b-col>
                        <b-button
                          variant="outline-danger"
                          class="mt-0"
                          @click="removeItem(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <!-- Stock & Packing -->
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Stock & Packing') }}</span>
            </template>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Hold stock before xx days expired"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Hold stock before xx days expired") }}</label>
                    <b-form-input />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Temperature Control Factor"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Temperature Control Factor") }}</label>
                    <v-select />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Is Packing Material"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Is Packing Material") }}</label>
                    <v-select :options="list" @input="sel1"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Queue Order"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Queue Order") }}</label>
                    <v-select />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Is Full Carton Pick Supported"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Is Full Carton Pick Supported") }}</label>
                    <v-select :options="list" @input="sel2"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Is Auto Hold Expiry Stocks"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t("Is Auto Hold Expiry Stocks") }}</label>
                    <v-select :options="list" @input="sel3"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Is Splittable')">
                  <v-select :options="list" @input="sel4"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Pack Foctor')">
                  <b-form-input v-model="detailInfo.packFactor"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('QTY to trigger stock alert email(O to disable alert for this SKU)')">
                  <b-form-input />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Is Allow Partial Allocation')">
                  <v-select :options="list" @input="sel5"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Is Expire Date Required')">
                  <v-select :options="list" @input="sel6"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Is Batch Required')">
                  <v-select :options="list" @input="sel7"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <!-- Size -->
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Size') }}</span>
            </template>
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Weight')+'(kg)'">
                  <b-form-input v-model="detailInfo.width"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Length')+'x'+$t('Width')+'x'+$t('Height')+'(cm)'">
                  <b-form-input />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Volume')+'(m3)'">
                  <b-form-input :disabled="true"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Box')+' '+$t('Weight')+'(kg)'">
                  <b-form-input v-model="detailInfo.netWeight"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Box')+' '+$t('Length')+'x'+$t('Width')+'x'+$t('Height')+'(cm)'">
                  <b-form-input />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Box')+' '+$t('Volume')+'(m3)'">
                  <b-form-input :disabled="true"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <!-- Custom Definition -->
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Custom Definition') }}</span>
            </template>
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Is UDF 1 Required')">
                  <v-select :options="list" @input="sel8"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Is UDF 2 Required')">
                  <v-select :options="list" @input="sel9"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Is UDF 3 Required')">
                  <v-select :options="list" @input="sel10"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Category Name')+' 1'">
                  <b-form-input v-model="detailInfo.categoryName1"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Category Name')+' 2'">
                  <b-form-input v-model="detailInfo.categoryName2"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Category Name')+' 3'">
                  <b-form-input v-model="detailInfo.categoryName3"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('DG Code (for Box C dangerous code only)')">
                  <v-select />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Is Serial Number Required')">
                  <v-select :options="list" @input="sel11"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Capture Serial Number In')">
                  <v-select />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-group label="">
                  <label class="mr-2">{{ $t("Serial Number Format") }}</label>
                  <b-button
                    variant="primary"
                    @click="repeateAgain1"
                  >
                    <span>{{ $t('Add') }}</span>
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                  </b-button>
                  <b-row v-for="(item,index) in items1" :key="index" :id="index"
                    class="mt-1">
                    <b-col lg="8">
                      <b-form-input />
                    </b-col>
                    <b-col lg="4">
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeItem1(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-group label="">
                  <label class="mr-2">{{ $t("Customs Data") }}</label>
                  <b-button
                    variant="primary"
                    @click="repeateAgain2"
                  >
                    <span>{{ $t('Add') }}</span>
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                  </b-button>
                  <b-row v-for="(item,index) in items2" :key="index" :id="index"
                    class="mt-1">
                    <b-col>
                      <b-form-input />
                    </b-col>
                    <b-col>
                      <b-form-input />
                    </b-col>
                    <b-col>
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeItem2(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-group label="">
                  <label class="mr-2">SKU {{ $t("Aliases") }}</label>
                  <b-button
                    variant="primary"
                    @click="repeateAgain3"
                  >
                    <span>{{ $t('Add') }}</span>
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                  </b-button>
                  <b-row v-for="(item,index) in items3" :key="index" :id="index"
                    class="mt-1">
                    <b-col lg="8">
                      <b-form-input />
                    </b-col>
                    <b-col lg="4">
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeItem3(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-group label="">
                  <label class="mr-2">SKU {{ $t("Barcodes") }}</label>
                  <b-button
                    variant="primary"
                    @click="repeateAgain4"
                  >
                    <span>{{ $t('Add') }}</span>
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                  </b-button>
                  <b-row v-for="(item,index) in items4" :key="index" :id="index"
                    class="mt-1">
                    <b-col lg="8">
                      <b-form-input />
                    </b-col>
                    <b-col lg="4">
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeItem4(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-group :label="$t('Specification')">
                  <b-button
                    variant="primary"
                    @click="repeateAgain5"
                  >
                    <span>{{ $t('Add') }}</span>
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                  </b-button>
                  <b-row v-for="(item,index) in items5" :key="index" :id="index"
                    class="mt-1">
                    <b-col lg="8">
                      <b-form-input />
                    </b-col>
                    <b-col lg="4">
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeItem5(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <!-- Other -->
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Other') }}</span>
            </template>
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Is Manufacture Date Required')">
                  <v-select :options="list" @input="sel12"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Manufacturer')+' '+$t('Name')">
                  <b-form-input v-model="detailInfo.manufacturer"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Manufacturer')+' '+$t('Address')">
                  <b-form-input />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Is Contain Battery')">
                  <v-select :options="list" @input="sel13"
                    v-model="detailInfo.includeBattery"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Battery Type')">
                  <v-select v-model="detailInfo.batteryType"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group :label="$t('Number of Battery')">
                  <b-form-input />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput,
  BButton, BImg,
  BCardHeader, BCardTitle, BCardBody,
  BBreadcrumb,
  BBreadcrumbItem, BFormRadio, BBadge, BFormTextarea,
  BFormCheckbox,BTab, BTabs,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, emails } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BImg,
    BFormRadio,
    BBadge,
    BFormTextarea,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BTab,
    BTabs,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    customerList: Array,
    detailInfo: Object,
  },
  data() {
    return {
      required,
      emails,
      baseInfo: {
        customer: '',
        createdAt: '',
        updatedAt: '',
        itemCode: '',
        itemRefCode: '',
        productID: '',
        itemName: '',
        itemTitle: '',
        itemDesc: '',
        itemType: '',
        brand: '',
        manufacturer: '',
        unit: '',
        packFactor: '',
        category: '',
        categoryName1: '',
        isHazmat: '',
        isFragile: '',
        includeBattery: '',
        cusDesc: '',
        hsCode: '',
        hsCodeCountry: '',
        length: '',
        width: '',
        height: '',
        netWeight: '',
        grossWeight: '',
        volumn: '',
        barcode: '',
        rotationOrder: '',
        shelfLife: '',
        offShelfLife: '',
        coo: '',
        snFormat: '',
        categoryName2: '',
        categoryName3: '',
        ownerCode: '',
      },
      items: [],
      items1: [],
      items2: [],
      items3: [],
      items4: [],
      items5: [],
      list: ['Yes', 'No']
    }
  },
  created() {},
  mounted() {
  },
  methods: {
    getDetail() {
      this.$http.get('skuMaster/getDetail', { params: { id: this.$route.params.id } }).then(res => {
        // console.log(res.data.data)
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.baseInfo = info
        }
      })
    },
    saveSku() {
      this.$http.put('skuMaster/save', this.baseInfo).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Save success',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    repeateAgain1() {
      this.items1.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem1(index) {
      this.items1.splice(index, 1)
    },
    repeateAgain2() {
      this.items2.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem2(index) {
      this.items2.splice(index, 1)
    },
    repeateAgain3() {
      this.items3.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem3(index) {
      this.items3.splice(index, 1)
    },
    repeateAgain4() {
      this.items4.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem4(index) {
      this.items4.splice(index, 1)
    },
    repeateAgain5() {
      this.items5.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem5(index) {
      this.items5.splice(index, 1)
    },
    getCustomer1(d) {},
    sel1(d) {},
    sel2(d) {},
    sel3(d) {},
    sel4(d) {},
    sel5(d) {},
    sel6(d) {},
    sel7(d) {},
    sel8(d) {},
    sel9(d) {},
    sel10(d) {},
    sel11(d) {},
    sel12(d) {},
    sel13(d) {},
  },
}
</script>
<style scoped>
.ll-p2{
  padding: 0.5rem;
}
.ll-radioBox{
  display: flex;
  justify-content: space-around;
}
.ll-bform1{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ll-cancel{
  top: 6rem;
}
.ll-hei{
  height: 530px;
}
.ll-6{
  margin-bottom: 0px;
}
.ll-box{
  width: 100%;
  display: flex;
  height: 230px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}
.ll-three{
  display: flex;
  justify-content: flex-end;
}
.tit1{
  font-weight: bold;
}
.tit2{
  font-weight: bold;
  font-size: 18px;
}
.tit3{
  font-size: 16px;
}
.ll-save{
  position: relative;
  left: 95%;
}
</style>
<style>
[dir] label {
  width: unset;
}
</style>
