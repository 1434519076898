<template>
  <section>
    <div class="ll-box d-flex justify-content-between">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Fulfillment') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active >
            {{ $t('SKU Master') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    <b-row class="mb-1 justify-content-end">
      <b-col cols="2" class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mr-1"
          v-b-modal.modal-upload
        >
          Upload
        </b-button>
      </b-col>
    </b-row>
    </div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md="6" v-if="false">
            <b-input-group>
              <b-input-group-prepend
                is-text
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle.filterS
                class="ll-outline"
                :style="filterBg">
                <svg class="ll-ficon" aria-hidden="true">
                  <use xlink:href="#icon-filter"></use>
                </svg>
              </b-input-group-prepend>
              <b-form-input
                v-model="condition.itemCode"
                class="searchInput"
                placeholder="Please enter item code"
              />
              <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                @click="searchById">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
                />
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
          <b-col md="2">
            <b-input-group class="input-group-merge ml-1 w-100">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                placeholder="SKU Code"
                v-model="condition.itemCode"
              />
            </b-input-group>
          </b-col>
          <b-col md="2">
            <b-input-group class="input-group-merge ml-1 w-100">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                placeholder="SKU Name"
                v-model="condition.itemName"
              />
            </b-input-group>
          </b-col>
          <b-col md="2">
            <v-select
              placeholder="Origin"
              v-model="origin"
              :options="originList"
              @input="selOrigin"
              label="codeDescription"
            />
          </b-col>
          <b-col md="2" v-if="isCan">
            <v-select
              :options="customerList"
              label="name"
              @input="getCustomer"
              placeholder="Client"
              v-model="customer"
            ></v-select>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="info"
              class="round mr-1"
              @click="getList"
            >
              {{ $t('Search') }}
            </b-button>
            <b-button
              variant="secondary"
              class="round"
              @click="onReset"
              v-show="isResetBtn"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
          <b-col cols="2" class="d-flex justify-content-end">
            <b-button
              variant="warning"
              v-b-modal.modal-export
              v-if="export1"
              >
                Export
              </b-button>
              <b-dropdown
                text="Export"
                variant="warning"
                v-b-modal.modal-exportPro
                v-if="export2"
                class="ll-dropdown"
              >
                <b-dropdown-item>Export new report</b-dropdown-item>
                <b-dropdown-item>Check export status</b-dropdown-item>
              </b-dropdown>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Filters sidebar -->
    <b-sidebar id="filterS" title=""
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3">
        <app-collapse class="ll-collapse">
          <app-collapse-item :title="$t('Customer')">
            <v-select
              :options="customerList"
              label="name"
              @input="getCustomer"
              placeholder="Please select customer"
              v-model="customer"
            ></v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Item Type')">
            <v-select
              :options="itemTypeList"
              @input="getItemType"
              v-model="itemType"
              label="itemType"
              placeholder="Please select item type"
            ></v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Item creation from-to')">
            <b-row>
              <b-col>
                <flat-pickr
                  id="formTime1"
                  v-model="beginDate"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                />
              </b-col>
              <b-col>
                <flat-pickr
                  id="toTime1"
                  v-model="endDate"
                  class="form-control"
                  :config="{ enableTime: false,dateFormat: 'Y-m-d H:i:ss'}"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear"
          >
            {{ $t('Clear') }}
          </b-button>
          <b-button variant="info"
            v-b-toggle.filterS
            @click="filterSearch">
            {{ $t('Search') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>

    <!-- table -->
    <b-table
      ref="refUserListTable"
      class="position-relative ll-table"
      :items="rows"
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      v-permission="[155]"
      :sticky-header="true"
      :busy="isBusy"
      :no-close-on-backdrop="true"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <!-- Column: Actions -->
      <!-- <template #cell(action)="data">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item >
            <feather-icon icon="EyeIcon" @click="editSKUDetail(data.item)"/>
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>
          <b-dropdown-item @click="editSKUDetail(data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
        </b-dropdown>
      </template> -->
      <template #cell(action)="data">
        <feather-icon
          v-show="data.item.id !== null"
          :id="`${data.item.id}`"
          icon="EditIcon"
          size="16"
          class="mx-1"
          @click="editSKUDetail(data.item)"
          v-permission="[157]"
        />
      </template>
    </b-table>
    <div class="d-flex justify-content-between flex-wrap ll-page">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <!-- upload -->
    <b-modal
      id="modal-upload"
      cancel-variant="outline-secondary"
      ok-title="Sure"
      centered
      title=""
      v-model="show"
    >
      <el-upload
        class="upload-demo ll-upload"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple
        accept=".csv,.xlsx"
        :on-success="uploadSuccess"
        :on-error="uploadFail"
        :on-progress="uploadPro">
        <!-- <i class="el-icon-upload"></i> -->
        <div class="el-upload__text" v-if="isDefault">
          {{uploadText}}
        </div>
        <div v-if="uLoading" class="el-upload__text">
          <p class="ll-uploadText">Uploading...</p>
          <b-spinner label="Loading..." />
        </div>
        <div v-if="uSuccess" class="el-upload__text">
          <svg aria-hidden="true" class="ll-success ll-uploadIcon">
            <use xlink:href="#icon-form_icon_normal"></use>
          </svg><br>
          <span class="ll-uploadText ll-success">Upload success</span>
        </div>
        <div v-if="uFail" class="el-upload__text ll-upload">
          <svg aria-hidden="true" class="ll-fail ll-uploadIcon">
            <use xlink:href="#icon-delete"></use>
          </svg><br>
          <span class="ll-uploadText ll-fail">Upload failed</span><br>
          <span class="ll-uploadText">ERROR: SKU {XXX} not found</span>
        </div>
        <div class="el-upload__tip" slot="tip">Download template here<br>Only accept CSV,xlsx etc</div>
      </el-upload>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show = false"
          v-if="false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
        >
          {{ $t('Upload') }}
        </b-button>
      </template>
    </b-modal>
    <!-- Export -->
    <b-modal
      id="modal-export"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="show1"
    >
      <div class="ll-ulBox">
        <span>Export to:</span>
        <ul>
          <li class="mb-1">
            <b-form-radio
              v-model="selected1"
              name="some-radios"
              value="a"
            >
              CSV
            </b-form-radio>
          </li>
          <li>
            <b-form-radio
              v-model="selected1"
              name="some-radios"
              value="b"
            >
              xlsx
            </b-form-radio>
          </li>
        </ul>
      </div>
      <div class="ll-ulBox">
        <span>Orders:</span>
        <ul>
          <li class="mb-1">
            <b-form-radio
              v-model="selected2"
              name="some-radios2"
              value="c"
            >
              All orders
            </b-form-radio>
          </li>
          <li>
            <b-form-radio
              v-model="selected2"
              name="some-radios2"
              value="d"
            >
              Selected<br>(Filtered with xxx,xxx,xxx)
            </b-form-radio>
          </li>
        </ul>
      </div>
      <div class="ll-ulBox">
        <span>Type:</span>
        <ul>
          <li class="mb-1">
            <b-form-radio
              v-model="selected3"
              name="some-radios3"
              value="e"
            >
              Order information
            </b-form-radio>
          </li>
          <li>
            <b-form-radio
              v-model="selected3"
              name="some-radios3"
              value="f"
            >
              Order with line items details
            </b-form-radio>
          </li>
        </ul>
      </div>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show1 = false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
          @click="exportHandle"
        >
          {{ $t('Export') }}
        </b-button>
      </template>
    </b-modal>
    <!-- Export progress-->
    <b-modal
      id="modal-exportPro"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title="Order Export Status"
      v-model="show2"
      size="xl"
    >
      <b-table :items="items" :fields="fields"
        :borderless='false' :hover="true"
        :sticky-header="true"
        class="ll-table"
      >
        <template #cell(action)="scope">
          <b-button
            class="btn-icon"
            variant="flat-danger"
            @click="del(scope.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-info"
            :href="scope.item.reportUrl"
            :disabled="scope.item.reportUrl===null"
            :style="{'cursor': (scope.item.reportUrl===null) ? 'no-drop' : 'pointer'}"
          >
            <feather-icon icon="ArrowDownIcon" />
          </b-button>
        </template>
        <template #cell(progress)="scope">
          <b-progress
            key="info"
            :max="scope.item.maxProgress"
            variant="info"
            class="progress-bar-info"
          >
            <b-progress-bar
              :value="scope.value"
              variant="info"
              :label="`${((scope.item.progress/scope.item.maxProgress) * 100)}%`"
            />
          </b-progress>
        </template>
      </b-table>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show2 = false"
        >
          {{ $t('Cancel') }}
        </b-button>
      </template>
    </b-modal>
    <!-- edit sku master -->
    <b-modal
      id="modal-edit"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      :title="title"
      size="xl"
      v-model="show3"
      hide-footer
    >
      <sku-details :detailInfo="detailInfo" :customerList="customerList"></sku-details>
    </b-modal>
  </section>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BTooltip,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BSidebar,
  VBToggle,
  BTable,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem,
  BBadge,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal, VBModal, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'
import skuDetails from './components/skuDetails1.vue'

export default {
  components: {
    skuDetails,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    flatPickr,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormRadio,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
    permission,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'createdAt', label: this.$t('Creation Date'), sortable: true },
        { key: 'customer', label: this.$t('Client'), sortable: true },
        { key: 'itemCode', label: this.$t('Code'), sortable: true },
        { key: 'barcode', label: this.$t('Barcode'), sortable: true },
        { key: 'itemName', label: this.$t('Name'), sortable: true },
        { key: 'origin', label: this.$t('Origin'), sortable: true },
        { key: 'lastUpdateTime', label: this.$t('Last Update Time'), sortable: true },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      statusList: [],
      condition: {
        page: '1',
        size: '50',
        orderBy: 'id',
        orderMole: 1,
        itemType: null,
        itemCode: null,
        fromDateTime: null,
        toDateTime: null,
        orgCode: null,
        origin: null,
        itemName: null,
      },
      beginDate: '',
      endDate: '',
      customerList: [],
      customer: '',
      itemTypeList: [],
      itemType: '',
      filterBg: {
        '--background': '#fff'
      },
      isBusy: true,
      isCan: true,
      export1: true,
      export2: false,
      show: false,
      show1: false,
      show2: false,
      show3: false,
      isDefault: true,
      uLoading: false,
      uSuccess: false,
      uFail: false,
      uploadText: 'Upload file here(drag the file or select from computer)',
      selected1: '',
      selected2: '',
      selected3: '',
      items: [],
      fields: [
        { key: 'exportTypes', label: 'Export Name' },
        { key: 'progress', label: 'Export Progress', class: 'll-progress' },
        { key: 'action', label: this.$t('Action') }
      ],
      originList: [],
      origin: null,
      isResetBtn: true,
      windowWidth: document.documentElement.clientWidth,
      title: null,
      detailInfo: null,
    }
  },
  computed: {},
  watch: {
    windowWidth(val) {
      if (val < 1460) {
        this.isResetBtn = false
      } else {
        this.isResetBtn = true
      }
    },
    // 'customer'(val) {
    //   this.setBg(val)
    // },
    // 'itemType'(val) {
    //   this.setBg(val)
    // },
    // 'beginDate'(val) {
    //   this.setBg(val)
    // },
    // 'endDate'(val) {
    //   this.setBg(val)
    // },
  },
  created() {
  },
  mounted() {
    this.getList()
    this.queryCustomer()
    // this.queryItemType()
    this.ability()
    this.queryOrigin()
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth
        this.windowWidth = window.fullWidth
      })()
    }
  },
  methods: {
    ability() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      const arr = []
      userData.roleDtos.map(i =>{
        arr.push(i.name)
      })
      const name = 'Super Admin'
      let flag= arr.findIndex(i => i === name)
      if (flag !== -1) {
        this.isCan = true
      } else {
        this.isCan = false
      }
    },
    // filter图标背景颜色设置
    setBg(val) {
      if (val !== null && val !== '') {
        this.filterBg= {
          '--background': '#ff9f43'
        }
      } else {
        this.filterBg= {
          '--background': '#fff'
        }
      }
    },
    // 首次查询
    getList() {
      this.$http.post('skuMaster/search', this.condition).then(res => {
        // console.log('ss', res.data.data)
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.rows = info.content
          this.page = info.page
          this.size = info.size
          this.totalSize = info.totalSize
          this.totalPage = info.totalPage
          this.isBusy = false
        }
      }).catch(error => console.log(error))
    },
    // 点击单行
    onRowClick() {
      // console.log('onRowClick')
    },
    onClear() {
      this.customer = null
      this.itemType = null
      this.beginDate = null
      this.endDate = null
    },
    // 条件过滤搜索
    filterSearch() {
      this.condition.fromDateTime = this.beginDate
      this.condition.toDateTime = this.endDate
      this.getList()
    },
    // 根据TKID查询
    searchById() {
      this.condition.itemCode = this.condition.itemCode.trim()
      this.getList()
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
      return params.sortDesc ? 0 : 1
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    queryItemType() {
      this.$http.get('skuMaster/getItemTypeList').then(res => {
        this.itemTypeList = res.data.data
      })
    },
    getItemType(t) {
      this.condition.itemType = t.itemType
      this.itemType = t.itemType
    },
    queryCustomer() {
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
        this.customerList = res.data.data
      })
    },
    getCustomer(s) {
      this.customer = s.name
      this.condition.orgCode = s.orgCode
    },
    uploadSuccess() {
      this.isDefault = false
      this.uLoading = false
      this.uSuccess = true
      this.uFail = false
    },
    uploadFail() {
      this.isDefault = false
      this.uLoading = false
      this.uSuccess = true
      this.uFail = false
    },
    uploadPro() {
      this.isDefault = false
      this.uLoading = true
      this.uSuccess = false
      this.uFail = false
    },
    exportHandle() {
      // if export success
      this.show1 = false
      this.export1 = false
      this.export2= true
    },
    queryOrigin() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'Origin'}}).then(res => {
        this.originList = res.data.data
      })
    },
    selOrigin(d) {
      this.origin = d.codeDescription
      this.condition.origin = d.codeName
    },
    onReset() {
      this.condition.itemCode = null
      this.condition.itemName = null
      this.origin = null
      this.customer = null
    },
    editSKUDetail(d) {
      this.title = d.itemName
      this.show3 = true
      this.$http.get(`/skuMaster/getDetail?id=${d.id}`).then(res => {
        this.detailInfo = res.data.data
      })
    }
  },
}
</script>
<style scoped>
.ll-page{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-collapse .card{
  box-shadow: none;
}
.ll-outline> div{
  background: var(--background);
}
.ll-ulBox ul{
  list-style: none;
  padding-inline-start: 20px;
}
</style>
<style>
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir=ltr] .b-sidebar.b-sidebar-right > .b-sidebar-header .close{
  margin-left: unset;
  margin-right: unset;
  font-size: 20px;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>
